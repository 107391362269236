import React from "react"
import styled, { keyframes } from "styled-components"
import { withPrefix } from "gatsby"

const Wrapper = styled.nav`
  position: absolute;
  right: 12px;
  bottom: 22px;
  bottom: ${({ isSlider }) => (isSlider ? -28 : 22)}px;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media (orientation: portrait) {
    display: none;
  }
`
const NavDotWrapper = styled.button`
  padding: 10px;
  height: 28px;
`

const anim = keyframes`
  0% {
    background-color: transparent;
  }
  
  50% {
    background-color: #000;
  }
  
  100% {
    background-color: transparent;
  }
`

const animCurr = keyframes`
  0% {
    background-color: transparent;
  }
  
  50% {
    background-color: #000;
  }
  
  100% {
    background-color: #000;
  }
`

const NavDot = styled.div`
  height: 8px;
  width: 8px;
  border: 1px solid black;
  border-radius: 50%;
  transition: background-color 0.25s ease-in-out;
  background-color: ${({ currDot }) => (currDot ? "#000" : "transparent")};
  animation: ${({ currDot, isSlider }) =>
      !isSlider ? (currDot ? animCurr : anim) : ""}
    0.15s ease-in-out ${({ i }) => 0.75 + (i + 1) * 0.05}s;

  @media (hover: hover) {
    ${NavDotWrapper}:hover & {
      background-color: #000;
    }
  }
`

const MiniImg = styled.img`
  position: absolute;
  height: 97px;
  width: 120px;
  left: -130px;
  display: block;
  max-width: 100vw;
  top: ${({ ind }) => `${ind * 20 - 50}px`};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;

  @media (hover: hover) {
    ${NavDotWrapper}:hover & {
      opacity: 1;
    }
  }
`

const Navigation = ({ allModels, setOpenModel, currDot, isSlider }) => {
  return (
    <Wrapper isSlider={isSlider}>
      {allModels &&
        allModels.map((modelItem, i) => {
          return (
            <NavDotWrapper
              onClick={() => setOpenModel(i)}
              key={modelItem.title}
            >
              <NavDot
                key={modelItem.title}
                currDot={currDot === i}
                isSlider={isSlider}
                i={i}
              >
                <MiniImg
                  src={`${withPrefix("/")}${modelItem.miniature}.jpg`}
                  title={"miniature"}
                  ind={i + 1}
                  // show={}
                />
              </NavDot>
            </NavDotWrapper>
          )
        })}
    </Wrapper>
  )
}

export default Navigation
