import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Wrapper from "../ImageWrapper"

const ImageP = () => {
  return (
    <Wrapper>
      <StaticImage
        src="../../../images/Reserved_SS22_P_36_a.jpeg"
        alt="Product"
      />
      <StaticImage
        src="../../../images/Reserved_SS22_P_36_b.jpeg"
        alt="Product"
      />
    </Wrapper>
  )
}

export default ImageP
