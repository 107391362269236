import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"
import { useInView } from "react-intersection-observer"
import lax from "lax.js"
import { useMediaQuery } from "react-responsive"
import { withPrefix } from "gatsby"

import Prompt from "./shared/Prompt"
import AvatarWrapper from "./avatars/AvatarWrapper"
import Navigation from "./models/Navigation"

const Wrapper = styled.div`
  height: 300vh;
  max-width: 100%;
`

const Inner = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  max-width: 100%;
  overflow: hidden;
`

const Slider = styled.div`
  width: 200vh;
  display: flex;
  padding-left: 31vw;
  padding-right: 25vw;
  will-change: transform;

  @media (orientation: portrait) {
    width: 200vh;
    padding-left: 18vw;
    padding-right: 18vw;
  }
`

const PromptWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
`

const NavWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 100%;
  transform: translate(-50%);
  z-index: 1;

  @media (orientation: portrait) {
    display: none;
  }
`

const Img = styled.img`
  will-change: transform;
`

const Avatars = ({ setOpenModel, offset, allModels, lang }) => {
  const [fromTop, setFromTop] = useState(null)
  const [showAltMess, setShowAltMess] = useState(false)
  const [currDot, setCurrDot] = useState(0)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  const { ref, inView } = useInView()

  useEffect(() => {
    if (fromTop) {
      setTimeout(() => {
        lax.addElements(".dc-slider", {
          scrollY: {
            translateX: [
              [fromTop, fromTop + window.innerHeight * 2],
              ["0", isPortrait ? "-screenWidth * 1" : "-screenWidth * 0.72"],
            ],
          },
        })
      }, 500)
    }
  }, [fromTop, isPortrait])

  const handleRe = () => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        setFromTop(
          parseInt(
            window.scrollY +
              document.getElementById("slider-wrapper").getBoundingClientRect()
                .top
          )
        )
      }, 500)
    }
  }

  useEffect(() => {
    handleRe()
    window.addEventListener("resize", handleRe, false)
    return () => {
      window.removeEventListener("resize", handleRe)
    }
  }, [])

  return (
    <Wrapper ref={ref} id="slider-wrapper">
      <Inner>
        <Slider className="dc-slider">
          <AvatarWrapper
            ind={0}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
            currDot={currDot}
            inView={inView}
            lang={lang}
          >
            <Img
              src={`${withPrefix("/")}av-1.png`}
              alt="Reserved Welcome 2 Dreamcore Avatar"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(0)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={1}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
            currDot={currDot}
            inView={inView}
            lang={lang}
          >
            <Img
              src={`${withPrefix("/")}av-2.png`}
              alt="Reserved Welcome 2 Dreamcore Avatar"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(1)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={2}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
            currDot={currDot}
            inView={inView}
            lang={lang}
          >
            <Img
              src={`${withPrefix("/")}av-3.png`}
              alt="Reserved Welcome 2 Dreamcore Avatar"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(2)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={3}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
            currDot={currDot}
            inView={inView}
            lang={lang}
          >
            <Img
              src={`${withPrefix("/")}av-4.png`}
              alt="Reserved Welcome 2 Dreamcore Avatar"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(3)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={4}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
            currDot={currDot}
            inView={inView}
            lang={lang}
          >
            <Img
              src={`${withPrefix("/")}av-5.png`}
              alt="Reserved Welcome 2 Dreamcore Avatar"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(4)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={5}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
            currDot={currDot}
            inView={inView}
            lang={lang}
          >
            <Img
              src={`${withPrefix("/")}av-6.png`}
              alt="Reserved Welcome 2 Dreamcore Avatar"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(5)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
        </Slider>
        <PromptWrapper>
          <Prompt>
            {showAltMess ? (
              <Trans>Explore avatar</Trans>
            ) : (
              <Trans>Scroll to browse avatars</Trans>
            )}
          </Prompt>
        </PromptWrapper>

        <NavWrapper>
          <Navigation
            allModels={allModels}
            setOpenModel={setOpenModel}
            currDot={currDot}
            inView={inView}
            lang={lang}
            isSlider={true}
          />
        </NavWrapper>
      </Inner>
    </Wrapper>
  )
}

export default Avatars
