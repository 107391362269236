import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

const Wrapper = styled.div`
  width: calc(100vw / 7);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  flex-shrink: 0;
  will-change: transform;
  pointer-events: none;

  @media (orientation: portrait) {
    width: calc(100vw / 5);
    margin-top: -50px;
  }
`

const Inner = styled.div`
  height: 90vh;
  width: 20vw;

  @media (orientation: portrait) {
    height: 100vh;
    width: 75vw;
  }

  img {
    height: 100%;
    width: auto;
  }

  .gatsby-image-wrapper,
  .img-wrap {
    will-change: transform;
    pointer-events: auto;
    position: relative;
    object-position: center;
    cursor: pointer;
    height: 100%;
    transform: scale(0);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 28px;
      width: 38px;
      background-image: ${({ lang }) =>
        lang === "pl" ? 'url("../dc_eye.svg")' : 'url("./dc_eye.svg")'};
      background-size: contain;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    @media (hover: hover) {
      transition: margin 0.15s ease-in-out;
      &:hover {
        margin-top: -10px;
        &:after {
          opacity: 1;
        }
      }
    }
  }
`

const AvatarWrapper = ({
  children,
  setCurrDot,
  currDot,
  ind,
  inView,
  lang,
}) => {
  const ref = useRef(null)
  const refInner = useRef(null)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  const onScroll = e => {
    if (currDot === ind && !isPortrait) {
      if (
        refInner.current.getBoundingClientRect().left - window.innerWidth / 5 >=
          0 &&
        refInner.current.getBoundingClientRect().left - window.innerWidth / 3 <
          0
      ) {
      } else if (
        refInner.current.getBoundingClientRect().left - window.innerWidth / 5 <
        0
      ) {
        setCurrDot(ind + 1)
      } else if (
        refInner.current.getBoundingClientRect().left - window.innerWidth / 3 >
        0
      ) {
        setCurrDot(ind - 1)
      }
    }

    window.requestAnimationFrame(() => {
      ref.current.querySelector(".img-wrap").style.transform = `
    translateX(${
      refInner.current.getBoundingClientRect().left - window.innerWidth / 5
    }px)
      
      scale(${
        (isPortrait ? 0.5 : 0.6) +
        (-1 *
          (refInner.current.getBoundingClientRect().left -
            window.innerWidth / 2)) /
          3000
      })
    `
    })
  }

  useEffect(() => {
    if (inView || isPortrait) {
      window.addEventListener("scroll", onScroll)
    } else {
      setCurrDot(0)
    }

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [currDot, inView, isPortrait])

  return (
    <Wrapper ref={ref}>
      <Inner ref={refInner} lang={lang}>
        <div className="img-wrap">{children}</div>
      </Inner>
    </Wrapper>
  )
}

export default AvatarWrapper
