import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { withPrefix } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import { useKey } from "react-use"

import ImageItem from "./ImageItem"
import MobileNav from "./MobileNav"
import Navigation from "./Navigation"
import Prompt from "../shared/Prompt"

const Wrapper = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f2f2f2;

  transition: opacity 0.15s ease-out;
  display: flex;

  @media (orientation: portrait) {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  model-viewer {
    height: 100%;
    width: 50%;
    background-color: #f2f2f2;

    --progress-bar-color: transparent;

    @media (orientation: portrait) {
      width: 100%;
      height: 60%;
    }
  }

  .hotspot {
    --min-hotspot-opacity: 0;
    display: block;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    padding: 20px;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    transition: opacity 0.25s ease-in-out;

    span {
      height: 100%;
      width: 100%;
      background: #fff;
      border-radius: 50%;
      display: block;
      pointer-events: none;

      @media (orientation: portrait) {
        display: none;
      }
    }

    &:not([data-visible]) {
      opacity: 0.3;
    }

    @media (hover: hover) {
      transition: padding 0.2s ease-in-out;

      &:hover {
        padding: 18px;
      }
    }

    @media (orientation: portrait) {
      height: 32px;
      width: 32px;
      padding: 0px;
      background: transparent;
      border: 2px solid #fff;
    }

    &--hide {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
`
const changeOpa = keyframes`
  0% {
    opacity: 1;
  }
  
  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
`
const Loading = styled.div`
  font-size: 16px;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${changeOpa} 0.5s ease-in-out infinite;

  @media (orientation: portrait) {
    padding-top: 50px;
    height: 55vh;
  }
`

const Close = styled.button`
  position: absolute;
  height: 58px;
  width: 58px;
  padding: 22px;
  top: 20px;
  right: 0;
  z-index: 1;

  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: 0.15s ease-in-out;

  @media (orientation: portrait) {
    position: fixed;
  }
  svg {
    height: 14px;
    width: 14px;

    @media (orientation: portrait) {
      height: 18px;
      width: 18px;
    }
  }
`

const TagsButton = styled.button`
  position: absolute;
  padding: 22px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;

  @media (orientation: portrait) {
    display: none;
  }
`

const ViewerPrompt = styled.div`
  position: absolute;
  bottom: 50px;
  left: 25%;
  transform: translate(-50%);
  z-index: 1;

  @media (orientation: portrait) {
    display: none;
  }
`

const ImagesPrompt = styled.div`
  position: absolute;
  bottom: 50px;
  left: 75%;
  transform: translate(-50%);
  z-index: 1;

  @media (orientation: portrait) {
    display: none;
  }
`

const Text = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
`

const Icon = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};

  svg {
    height: 17px;
    width: 17px;
    margin-top: 8px;
    margin-left: 5px;
  }
`

const ImagesWrapper = styled.div`
  width: 50%;
  overflow-y: scroll;

  @media (orientation: portrait) {
    width: 100%;
    height: auto;
  }
`

const ModelOverlay = ({
  data,
  show,
  setOpenModel,
  setOpenProduct,
  setShowIndex,
  allModels,
  ind,
  hideClose,
  isProdOpen,
}) => {
  const [showTags, setShowTags] = useState(true)

  const handleEscape = (e) => {
    if (e.key == "Escape" || e.key == "Esc" || e.keyCode == 27) {
      e.preventDefault()
      setOpenModel(null)
      return false
    }
  }

  useEffect(() => {
    if (!isProdOpen) {
      window.addEventListener("keydown", handleEscape)
    }

    return () => {
      if (!isProdOpen) {
        window.removeEventListener("keydown", handleEscape)
      }
    }
  }, [isProdOpen])

  return (
    <Wrapper show={show}>
      <Close onClick={() => setOpenModel(null)} show={!hideClose}>
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0013 0.897148L13.1042 0L7.00067 6.10285L0.897148 0L0 0.897148L6.10352 7L0 13.1029L0.897148 14L7.00067 7.89715L13.1042 14L14.0013 13.1029L7.89782 7L14.0013 0.897148Z"
            fill="black"
          />
        </svg>
      </Close>
      <TagsButton onClick={() => setShowTags(!showTags)}>
        <Trans>Tags</Trans>
        <Text show={showTags}>
          <Trans>On</Trans>
        </Text>
        <Text show={!showTags}>
          <Trans>Off</Trans>
        </Text>
        <Icon show={showTags}>
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 73.8 73.8"
          >
            <path
              d="M67.7,37.3L36.5,6.1c-1-1.2-2.7-1.9-4.4-1.9H11.3C7.7,4.2,5,6.9,5,10.5v20.8c0,1.7,0.6,3.3,1.9,4.4l31.2,31.3
	c1.2,1.2,2.9,1.9,4.4,1.9s3.1-0.6,4.4-1.9l20.8-20.8C70.2,43.8,70.2,39.6,67.7,37.3z M19.6,23c-2.3,0-4.2-1.9-4.2-4.2
	s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2S21.9,23,19.6,23z"
            />
          </svg>
        </Icon>
        <Icon show={!showTags}>
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 73.8 73.8"
          >
            <path
              d="M42.4,71.4c-2.2,0-4.5-1-6.1-2.6L5,37.6c-1.7-1.5-2.7-3.7-2.7-6.2V10.6c0-4.9,3.8-8.8,8.8-8.8h20.8c2.5,0,4.7,1,6.2,2.7
       l31.1,31.1c1.7,1.6,2.6,3.8,2.6,6.2s-1,4.6-2.6,6.2L48.5,68.8C46.9,70.5,44.6,71.4,42.4,71.4z M11.1,6.9c-2.2,0-3.8,1.6-3.8,3.8
       v20.8c0,1,0.4,1.9,1,2.5l0.2,0.2l31.2,31.3c0.8,0.8,1.9,1.1,2.6,1.1s1.8-0.3,2.6-1.1l20.9-20.9c0.7-0.6,1.1-1.5,1.1-2.5
       s-0.4-1.9-1.1-2.5l-0.1-0.1L34.4,7.8c-0.5-0.6-1.4-1-2.5-1L11.1,6.9L11.1,6.9z M19.4,25.6c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7
       s6.7,3,6.7,6.7S23.1,25.6,19.4,25.6z M19.4,17.3c-0.9,0-1.7,0.7-1.7,1.7s0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7S20.4,17.3,19.4,17.3z"
            />
          </svg>
        </Icon>
      </TagsButton>

      <ViewerPrompt>
        <Prompt icon={"rotate"}>
          <Trans>Rotate and explore</Trans>
        </Prompt>
      </ViewerPrompt>

      <ImagesPrompt>
        <Prompt icon={"scroll"}>
          <Trans>Scroll for more</Trans>
        </Prompt>
      </ImagesPrompt>

      <Navigation
        allModels={allModels}
        setOpenModel={setOpenModel}
        currDot={ind}
      ></Navigation>

      <model-viewer
        alt=""
        src={`${withPrefix("/")}${data.model}.glb`}
        seamless-poster
        shadow-intensity="1"
        camera-controls
        disable-zoom
        interaction-prompt="none"
        auto-rotate
        auto-rotate-delay="500"
        touch-action="none"
      >
        <div id="lazy-load-poster" slot="poster">
          <Loading>
            <Trans>Loading</Trans>
          </Loading>
        </div>
      </model-viewer>

      <MobileNav
        showTags={showTags}
        setShowTags={setShowTags}
        allModels={allModels}
        setShowIndex={setShowIndex}
      />

      <ImagesWrapper onScroll={() => setOpenProduct(-1)}>
        {data.images &&
          data.images.map((item, i) => {
            return (
              <ImageItem
                data={item}
                key={item.title + i}
                setOpenProduct={setOpenProduct}
                showTags={showTags}
              ></ImageItem>
            )
          })}
      </ImagesWrapper>
    </Wrapper>
  )
}

export default ModelOverlay
