import React from "react"
import styled from "styled-components"
import { withPrefix } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"

const Wrapper = styled.div`
  display: none;

  @media (orientation: portrait) {
    position: fixed;
    z-index: 999999999999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f2f2f2;

    display: ${({ show }) => (show ? "block" : "none")};
    transition: opacity 0.15s ease-out;
  }
`
const SecondWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0px);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000;
`
const Top = styled.div`
  padding: 60px 22px 40px;
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 24px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Heading = styled.h3`
  font-size: 14px;
`

const Close = styled.button`
  position: fixed;
  height: 62px;
  width: 58px;
  padding: 22px;
  top: 27px;
  right: 0;
  z-index: 1;

  svg {
    height: 18px;
    width: 18px;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 22px;
`

const Item = styled.li`
  list-style: none;
  padding: 0;
  display: flex;
  padding: 18px 0 33px;
  border-bottom: 1px solid #333333;
  margin: 0;

  &:last-of-type {
    border-bottom: 0;
  }
`
const MiniImg = styled.img`
  height: auto;

  width: 120px;
  display: block;
  max-width: 100vw;
  margin-right: 40px;
`

const Text = styled.div`
  font-size: 16px;
  line-height: 20px;
`

const MobileIndex = ({ show, setShow, allModels, setOpenModel }) => {
  return (
    <Wrapper show={show}>
      <SecondWrapper>
        <Top>
          <Heading>Avatars</Heading>

          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 17"
          >
            <circle id="Oval" className="st0" cx="1.5" cy="1.5" r="1.5" />
            <circle id="Oval-Copy" className="st0" cx="8.5" cy="1.5" r="1.5" />
            <circle
              id="Oval-Copy-2"
              className="st0"
              cx="1.5"
              cy="8.5"
              r="1.5"
            />
            <circle
              id="Oval-Copy-3"
              className="st0"
              cx="8.5"
              cy="8.5"
              r="1.5"
            />
            <circle
              id="Oval-Copy-4"
              className="st0"
              cx="8.5"
              cy="15.5"
              r="1.5"
            />
            <circle
              id="Oval-Copy-5"
              className="st0"
              cx="1.5"
              cy="15.5"
              r="1.5"
            />
          </svg>

          <Close onClick={() => setShow(false)}>
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0013 0.897148L13.1042 0L7.00067 6.10285L0.897148 0L0 0.897148L6.10352 7L0 13.1029L0.897148 14L7.00067 7.89715L13.1042 14L14.0013 13.1029L7.89782 7L14.0013 0.897148Z"
                fill="black"
              />
            </svg>
          </Close>
        </Top>

        <List>
          {allModels &&
            allModels.map((modelItem, i) => {
              return (
                <Item
                  key={modelItem.title}
                  onClick={() => {
                    setShow(false)
                    setOpenModel(i)
                  }}
                >
                  <MiniImg
                    src={`${withPrefix("/")}${modelItem.miniature}.jpg`}
                    title={"miniature"}
                    ind={i + 1}
                  />
                  <Text>
                    {modelItem.garments &&
                      modelItem.garments.map((garment, j) => {
                        return (
                          <Text key={garment + j}>
                            <Trans>{garment}</Trans>
                          </Text>
                        )
                      })}
                  </Text>
                </Item>
              )
            })}
        </List>
      </SecondWrapper>
    </Wrapper>
  )
}

export default MobileIndex
