import React from "react"
import styled from "styled-components"
import Heading from "./Heading"
import { Trans } from "gatsby-plugin-react-i18next"

const Wrapper = styled.div`
  padding-top: 160px;
  padding-bottom: 120px;

  @media (orientation: portrait) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
`

const VideoHeading = () => {
  return (
    <Wrapper>
      <Heading>
        <Trans>Welcome 2 Dreamcore</Trans>
      </Heading>
    </Wrapper>
  )
}

export default VideoHeading
