import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  .gatsby-image-wrapper {
    position: absolute;
    border-radius: 14px;
    overflow: hidden;
    will-change: transform;
    height: 100%;

    img {
      height: 100%;
    }

    &:last-of-type {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.15s ease-in-out;
    }
  }
`

const ImageWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default ImageWrapper
