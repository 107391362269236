import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { ContextProviderComponent } from "../helpers/context"
import { GlobalStyle } from "../components/GlobalStyle"
import { navigate } from "gatsby"
import qs from "query-string"
import { enableBodyScroll } from "body-scroll-lock"
import { withPrefix } from "gatsby"
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next"
import { useCookies } from "react-cookie"

import "../fonts/fonts.css"

import Spacer from "../components/styled/Spacer"

import Loader from "../components/Loader"
import Anchor from "../components/Anchor"

import Header from "../components/Header"
import Top from "../components/Top"
import SlidersWrapper from "../components/SlidersWrapper"
import VideoHeading from "../components/VideoHeading"
import Video from "../components/Video"
import Lookbook from "../components/Lookbook"
import Filter from "../components/Filter"
import MobileIndex from "../components/MobileIndex"

import models from "../../content/models.json"
import products from "../../content/products.json"
import markets from "../../content/markets.json"

import ModelOverlay from "../components/models/ModelOverlay"
import Product from "../components/Product"

export default function Home({ location, ...props }) {
  const context = React.useContext(I18nextContext)
  const [openModel, setOpenModel] = useState(null)
  const [openProduct, setOpenProduct] = useState(-1)
  const [showLoader, setShowLoader] = useState(true)
  const [wait, setWait] = useState(true)
  const [showIndex, setShowIndex] = useState(false)
  const [storedScrollY, setStoredScrollY] = useState(false)
  const [market, setMarket] = useState(null)
  const [marketId, setMarketId] = useState(null)
  const [showLb, setShowLb] = useState(false)
  const [showVid, setShowVid] = useState(null)
  const [showAv, setShowAv] = useState(false)
  const [cookies, setCookie] = useCookies(["dc-re"])
  const [showLang, setShowLang] = useState(false)
  const [showVersion, setShowVersion] = useState(false)

  const { t } = useTranslation()

  const handleRe = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  useEffect(() => {
    const queryString = qs.parse(location.search)
    const marketFromQs = Object.keys(queryString)
    if (marketFromQs) {
      if (!cookies["dc-re"]) {
        setCookie("dc-re", 1, {
          path: "/",
          expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        })

        if (marketFromQs[0] && marketFromQs[0].includes("_pl")) {
          if (context.language !== "pl") {
            navigate(`/pl/?${marketFromQs[0]}`)
          }
        }
      }

      setMarket(marketFromQs[0])
    }

    handleRe()
    window.addEventListener("resize", handleRe, false)
    return () => {
      window.removeEventListener("resize", handleRe)
    }
  }, [context.language])

  useEffect(() => {
    if (market && markets) {
      if (markets[market]) {
        setMarketId(markets[market])
      }
    }
  }, [market])

  useEffect(() => {
    setStoredScrollY(window.scrollY)
    if (openModel !== null || wait || showLoader) {
      document.body.style.setProperty("top", `${window.scrollY * -1}px`)
      document.body.style.setProperty("overflow", `hidden`)
      document.body.style.setProperty("height", `100vh`)
    } else {
      enableBodyScroll(document.body)
      document.body.style.setProperty("top", "")
      document.body.style.setProperty("overflow", `initial`)
      document.body.style.setProperty("height", `auto`)
      document.body.scrollTo(0, storedScrollY)
    }
  }, [openModel, wait, showLoader])

  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Page Title")}</title>
        <meta property="description" content={t("Page Desc")} />
        <meta property="og:title" content={t("Seo Heading")} />
        <meta property="og:description" content={t("Page Desc")} />
        <meta property="og:type" content={"website"} />
        <meta
          property="og:image"
          content={`${withPrefix("/")}dreamcore-og.jpeg`}
        />
        <meta property="twitter:card" content={"summary"} />
        <meta property="twitter:creator" content={"Reserved"} />
        <meta property="twitter:title" content={t("Seo Heading")} />
        <meta property="twitter:description" content={t("Page Desc")} />
        <link rel="canonical" href="https://welcome2dreamcore-women.reserved.com/" />

        <script
          type="module"
          src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}favicon/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${withPrefix("/")}favicon/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${withPrefix("/")}favicon/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`${withPrefix("/")}favicon/site.webmanifest`}
        />

      </Helmet>
      <ContextProviderComponent>
        <Loader showLoader={showLoader} wait={wait} setWait={setWait} />

        <Header
          showLb={showLb && openModel === null}
          showVid={showLb && showVid && openModel === null}
          showAv={showLb && showVid && showAv && openModel === null}
          market={market}
          showLang={showLang}
          setShowLang={setShowLang}
          showVersion={showVersion}
          setShowVersion={setShowVersion}
        />

        <Spacer />

        <Top
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          isShowingLoader={showLoader || wait}
        />

        <Anchor name="lb" setShow={setShowLb} />

        <Lookbook setOpenProduct={setOpenProduct} />

        <Anchor name="vid" setShow={setShowVid} />

        <VideoHeading />

        <Video
          btnText={"Watch video"}
          btnTextMob={"Watch"}
          srcDt={`https://player.vimeo.com/progressive_redirect/playback/685395465/rendition/1080p?loc=external&signature=26e333cd71ca625a33400c91efa2ea885485783074e222625e04688fe12dd813`}
          srcMob={`https://player.vimeo.com/progressive_redirect/playback/685395281/rendition/1080p?loc=external&signature=c1823a9c0db030580882c353d49876850d7b753699a1665dafd5252a104976e2`}
          posterDt={`${withPrefix("/")}poster-1-dt.jpg`}
          posterMob={`${withPrefix("/")}poster-1-mob.jpg`}
        />

        <Anchor name="av" setShow={setShowAv} />

        <SlidersWrapper
          setOpenModel={setOpenModel}
          allModels={models.data.models}
          lang={context.language}
        />

        <Video
          btnText={"Immerse yourself"}
          btnTextMob={"Watch"}
          srcDt={`https://player.vimeo.com/progressive_redirect/playback/683753282/rendition/1080p?loc=external&signature=6cad7de29450e3d403e4c3bba864c969e0811d8eb9fd915905a7981443de52d8`}
          srcMob={`https://player.vimeo.com/progressive_redirect/playback/683778537/rendition/1080p?loc=external&signature=76ee81fca669965bde8283322793f1ae1aec0374a7d54125fbd0ad58a069f5bb`}
          posterDt={`${withPrefix("/")}poster-2-dt.jpeg`}
          posterMob={`${withPrefix("/")}poster-2-mob.png`}
          marginB
        />
        <Filter />

        {models.data.models.map((item, i) => {
          return (
            i === openModel && (
              <ModelOverlay
                key={item.title}
                data={item}
                show={i === openModel}
                ind={i}
                setOpenModel={setOpenModel}
                setOpenProduct={setOpenProduct}
                allModels={models.data.models}
                setShowIndex={setShowIndex}
                hideClose={openProduct >= 0 || showLang}
                isProdOpen={openProduct >= 0}
              ></ModelOverlay>
            )
          )
        })}

        {products.data.products.map((item, i) => {
          return (
            i + 1 === openProduct && (
              <Product
                key={item.title + i}
                data={item}
                show={i + 1 === openProduct}
                setOpenProduct={setOpenProduct}
                marketId={marketId}
                market={market}
              ></Product>
            )
          )
        })}

        <MobileIndex
          show={showIndex}
          setShow={setShowIndex}
          allModels={models.data.models}
          setOpenModel={setOpenModel}
        />
      </ContextProviderComponent>
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
