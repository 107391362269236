import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO42 = () => {

  return (
    <StaticImage
      src="../../images/JPG-FEMALE-LOOK004-2.jpg"
      
      alt="Reserved avatar"

    />
  )
}

export default ImageMO42
