import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO61 = () => {

  return (
    <StaticImage
      src="../../images/JPG-FEMALE-LOOK006-1.jpg"
      
      alt="Reserved avatar"
    />
  )
}

export default ImageMO61
