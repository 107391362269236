import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
`

const Video = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Poster = styled.img`
  display: ${({ show }) => show ? 'block' : 'none'};;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Controls = styled.div`
  display: ${({ isTouch }) => (isTouch ? "none" : "block")};
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 37px;
  z-index: 3;
  padding: 0 40px;
  opacity: ${({ controlsVisible, isPlaying }) =>
    controlsVisible && isPlaying ? "1" : "0"};
  transition: opacity 0.2s ease-in-out;
`

const ControlsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const TimePlayed = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
`

const Buttons = styled.div`
  display: flex;
`

const Mute = styled.button`
  display: block;
  margin: 0;
  border: 0;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`

const PlayPause = styled.button`
  display: ${({ isTouch }) => (isTouch ? "none" : "block")};
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-indent: -9999px;
  border: 0;
  padding: 0;
  margin: 0;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ isPlaying }) => (isPlaying ? "0" : "1")};
  background-size: 7%;

  &:focus {
    outline: none;
  }
`

const ProgressWrapper = styled.div`
  height: 2px;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
`

const ProgressBar = styled.div`
  display: block;
  width: 0%;
  height: 100%;
  background-color: #fff;
`

const VideoOverlay = ({
  showVideo,
  src,
  loopWithSound,
  poster,
  innerRefPlayEl,
  setShowButton,
}) => {
  const mainWrapper = useRef(null)
  const vid = useRef(null)
  const progressBar = useRef(null)

  let timerInterval = useRef(null)
  let controlsInterval = useRef(null)

  const [timePlayed, setTimePlayed] = useState("00:00")
  const [controlsVisible, setControlsVisible] = useState(false)
  const [areControlsVisible, setAreControlsVisible] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [showPoster, setShowPoster] = useState(true)
  const [isTouch, setIsTouch] = useState(false)
  const [playPosX, setPlayPosX] = useState(0)
  const [playPosY, setPlayPosY] = useState(0)

  const exitFullScreenMobile = () => {
    if (!isFullscreen) {
      const mq =
        typeof window !== "undefined"
          ? window.matchMedia(
              "only screen and (min-width: 813px) and (orientation: landscape), only screen and (min-width: 1024px)"
            )
          : false
      const isDesktop = mq ? mq.matches : false
      if (!isDesktop) {
        closeVideo()
      }
    }
  }

  const closeVideo = () => {
    setIsPlaying(true)
    vid.current.pause()
    vid.current.currentTime = 0
    pauseVideo()
    setShowButton(true)
  }

  const playVideo = () => {
    vid.current.play()

    timerInterval.current = setInterval(timer, 1000)
    setShowButton(false)
    setIsPlaying(true)
    setShowPoster(false)
  }

  const pauseVideo = () => {
    vid.current.pause()
    setShowButton(true)
    clearInterval(timerInterval.current)

    setIsPlaying(false)
  }

  const playPause = setting => {
    if (vid.current.paused || vid.current.ended) {
      playVideo()
    } else {
      pauseVideo()
    }
  }

  const formattedTime = time => {
    const minutes = Math.floor(time / 60)

    let seconds = time % 60

    if (seconds < 10) {
      seconds = `0${seconds}`
    }

    // The output in MM:SS format
    return `0${minutes || "0"}:${seconds || "00"}`
  }

  const timer = () => {
    if (vid.current) {
      setTimePlayed(formattedTime(Math.round(vid.current.currentTime)))
    }
  }

  const handleMute = () => {
    vid.current.muted = !vid.current.muted
    setIsMuted(!isMuted)
  }

  // handling fullscreen state

  const handleFullscreen = () => {
    if (isFullScreen()) {
      if (document.exitFullscreen) document.exitFullscreen()
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
      else if (document.webkitCancelFullScreen)
        document.webkitCancelFullScreen()
      else if (document.msExitFullscreen) document.msExitFullscreen()
      setIsFullscreen(false)
    } else {
      if (mainWrapper.current.requestFullscreen)
        mainWrapper.current.requestFullscreen()
      else if (mainWrapper.current.mozRequestFullScreen)
        mainWrapper.current.mozRequestFullScreen()
      else if (mainWrapper.current.webkitRequestFullScreen)
        mainWrapper.current.webkitRequestFullScreen()
      else if (mainWrapper.current.msRequestFullscreen)
        mainWrapper.current.msRequestFullscreen()
      setIsFullscreen(true)
    }
  }

  const isFullScreen = () => {
    return !!(
      document.fullScreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    )
  }

  const setDurationVal = () => {
    progressBar.current.setAttribute(
      "data-max",
      Math.round(vid.current.duration)
    )
  }

  const handleSkipAhead = event => {
    const pos =
      (event.pageX -
        (progressBar.current.parentElement.offsetLeft +
          vid.current.parentElement.offsetLeft)) /
      progressBar.current.parentElement.offsetWidth
    vid.current.currentTime = pos * vid.current.duration

    timer()
  }

  const handleTime = () => {
    if (!progressBar.current.getAttribute("data-max"))
      progressBar.current.setAttribute("data-max", vid.current.duration)

    progressBar.current.style.width =
      Math.round((vid.current.currentTime / vid.current.duration) * 100) + "%"

    if (vid.current.ended) {
      vid.current.currentTime = 0

      setIsPlaying(false)
    }
  }

  const showControls = () => {
    if (!isTouch) setControlsVisible(true)
  }

  const hideControls = () => {
    if (!isTouch) {
      setControlsVisible(false)

      clearTimeout(controlsInterval.current)
    }
  }

  const handleMouseMove = e => {
    setPlayPosX(e.clientX)
    setPlayPosY(e.clientY)
  }

  const watchControls = e => {
    handleMouseMove(e)

    if (!isTouch) {
      clearTimeout(controlsInterval.current)

      if (areControlsVisible) {
        setControlsVisible(true)
        setAreControlsVisible(false)
      }

      controlsInterval.current = setTimeout(() => {
        setControlsVisible(false)
        setAreControlsVisible(true)
      }, 3000)
    }
  }

  useEffect(() => {
    if ("ontouchstart" in window || navigator.msMaxTouchPoints > 0) {
      setIsTouch(true)
    } else {
      setIsTouch(false)
    }

    vid.current.addEventListener("webkitendfullscreen", function () {
      setIsFullscreen(false)
      exitFullScreenMobile()
    })

    return () => {
      vid.current &&
        vid.current.removeEventListener("webkitendfullscreen", function () {
          setIsFullscreen(false)
          exitFullScreenMobile()
        })
    }
  }, [vid])

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        if (!isFullscreen) {
          closeVideo()
        }
      }
    }

    document.addEventListener("keyup", handleEsc)
    document.addEventListener("fullscreenchange", function (e) {
      setIsFullscreen(!!(document.fullScreen || document.fullscreenElement))
    })
    document.addEventListener("webkitfullscreenchange", function () {
      setIsFullscreen(!!document.webkitIsFullScreen)
    })
    document.addEventListener("mozfullscreenchange", function () {
      setIsFullscreen(!!document.mozFullScreen)
    })
    document.addEventListener("msfullscreenchange", function () {
      setIsFullscreen(!!document.msFullscreenElement)
    })
    return () => {
      document.removeEventListener("fullscreenchange", function (e) {
        setIsFullscreen(!!(document.fullScreen || document.fullscreenElement))
      })
      document.removeEventListener("webkitfullscreenchange", function () {
        setIsFullscreen(!!document.webkitIsFullScreen)
      })
      document.removeEventListener("mozfullscreenchange", function () {
        setIsFullscreen(!!document.mozFullScreen)
      })
      document.removeEventListener("msfullscreenchange", function () {
        setIsFullscreen(!!document.msFullscreenElement)
      })
      document.removeEventListener("keyup", handleEsc)
    }
  }, [])

  useEffect(() => {
    exitFullScreenMobile()
  }, [isFullscreen])

  useEffect(() => {
    if (loopWithSound) {
      vid.current.muted = true
      setIsMuted(true)

      playVideo()
    }
  }, [loopWithSound, vid])

  return (
    <Wrapper
      ref={mainWrapper}
      isFullscreen={false}
      showVideo={showVideo}
      onMouseEnter={showControls}
      onMouseLeave={hideControls}
      onMouseMove={watchControls}
    >
      <VideoWrapper isFullscreen={isFullscreen}>
        <Video
          ref={vid}
          onTimeUpdate={handleTime}
          onLoadedMetadata={setDurationVal}
          playsInline
          poster={poster}
        >
          <source src={src} type="video/mp4" />
        </Video>

        <Poster src={poster} show={showPoster} title="Welcome 2 Dreamcore video poster" alt="Video poster"></Poster>

        <PlayPause
          isTouch={isTouch}
          controlsVisible={controlsVisible}
          isPlaying={isPlaying}
          onClick={playPause}
          type="button"
          className={"popup-video-play-pause"}
          ref={innerRefPlayEl}
        >
          Play/Pause
        </PlayPause>
      </VideoWrapper>
      <Controls
        isTouch={isTouch}
        controlsVisible={controlsVisible}
        isPlaying={isPlaying}
      >
        <ControlsTop>
          <TimePlayed>{timePlayed}</TimePlayed>
          <Buttons>
            <Mute onClick={handleMute} isMuted={isMuted} type="button">
              Sound {isMuted ? "on" : "off"}
            </Mute>
          </Buttons>
        </ControlsTop>
        <ProgressWrapper onClick={handleSkipAhead}>
          <ProgressBar ref={progressBar} data-max="0"></ProgressBar>
        </ProgressWrapper>
      </Controls>
    </Wrapper>
  )
}

export default VideoOverlay
