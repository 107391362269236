import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { withPrefix } from "gatsby"

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: auto;
  padding-right: 100px;
  display: block;
  width: auto;
  flex-grow: 1;

  &:last-of-type {
    padding-right: 0;
  }

  @media (orientation: portrait) {
    height: auto;
    padding-right: 0;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  margin-top: ${({ size }) =>
    size === "2" ? "10vh" : size === "3" ? "25vh" : "0"};
  height: ${({ size }) =>
    size === "2" ? "80vh " : size === "3" ? "50vh" : "100vh"};

  width: 200px;
  overflow: hidden;

  @media (orientation: portrait) {
    margin: 0;
    height: auto;
    margin-bottom: 10px;

    margin-left: ${({ size }) =>
      size === "2" ? "20px" : size === "3" ? "60px" : "0"};

    width: ${({ size }) =>
      size === "2"
        ? "calc(100% - 40px)"
        : size === "3"
        ? "calc(100% - 120px)"
        : "100%"} !important;
  }
`

const Img = styled.img`
  height: auto;
  width: 100px;
  display: block;
  opacity: 0;

  @media (orientation: portrait) {
    width: 100%;
    opacity: 1;
  }

  &.full-h {
    height: 100% !important;
    width: 100% !important;
    opacity: 1;

    @media (orientation: portrait) {
      width: 100% !important;
      height: auto !important;
    }
  }
`

const Dot = styled.button`
  height: 54px;
  width: 54px;
  border-radius: 50%;
  padding: 20px;

  position: absolute;
  top: ${({ posY }) => posY};
  left: ${({ posX }) => posX};

  span {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 50%;
    display: block;
    pointer-events: none;

    @media (orientation: portrait) {
      display: none;
    }
  }

  @media (hover: hover) {
    transition: padding 0.2s ease-in-out;

    &:hover {
      padding: 18px;
    }
  }

  @media (orientation: portrait) {
    height: 32px;
    width: 32px;
    padding: 0px;
    background: transparent;
    border: 2px solid #fff;
  }
`

const Item = ({ data, first, setOpenProduct }) => {
  const wrapperEl = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [elWidth, setElWidth] = useState(0)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  useEffect(() => {
    if (wrapperEl.current) {
      var img = new Image()
      img.onload = function () {
        const gatsbyImage = wrapperEl.current.querySelector("img")

        if (gatsbyImage) {
          const ratio = gatsbyImage.offsetWidth / gatsbyImage.offsetHeight
          setElWidth(
            data.size === "2"
              ? `${parseInt(80 * ratio)}vh`
              : data.size === "3"
              ? `${parseInt(50 * ratio)}vh`
              : `${parseInt(100 * ratio)}vh`
          )


          gatsbyImage.classList.add("full-h")
        }
      }
      img.src = `${withPrefix("/")}Reserved_SS22_LB${data.image_id}.jpg`
    }
  }, [wrapperEl.current])

  return (
    <Wrapper ref={wrapperEl}>
      <ImageWrapper
        size={data.size || 1}
        style={
          isPortrait
            ? {}
            : {
                width: elWidth || "60vh",
              }
        }
      >
        <Img
          src={`${withPrefix("/")}Reserved_SS22_LB${data.image_id}.jpg`}
          alt="Reserved Welcome 2 Dreamcore Collection Image"
          // onLoad={() => setLoaded(true)}
        />

        {data.hotspots &&
          data.hotspots.map((item, i) => {
            return (
              <Dot
                type="button"
                key={item.hs_id}
                posX={item.posX}
                posY={item.posY}
                onClick={() => {
                  setOpenProduct(item.product_id)
                }}
              >
                <span></span>
              </Dot>
            )
          })}
      </ImageWrapper>
    </Wrapper>
  )
}

export default Item
