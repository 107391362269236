import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Ticker from './Ticker'

const Wrapper = styled.div``

const Text = styled.h2`
  white-space: nowrap;
  font-size: 140px;
  line-height: 1;
  padding-right: 80px;
  display: block;
  height: 200px;
  
  @media (orientation: portrait) {
    font-size: 30px;
    height: 50px;
    padding-right: 40px;
  }
`

const Heading = ({ children, speed }) => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    document.fonts.ready.then(function (e) {
      setReady(true)
    })
  }, [])

  return (
    <Wrapper>
      {ready && (
        <Ticker speed={speed || 9}>
          <Text>{children}</Text>
        </Ticker>
      )}
    </Wrapper>
  )
}

export default Heading
