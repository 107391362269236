import React, { useEffect, useRef } from "react"
import { useSpring, animated, to } from "react-spring"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { useInView } from "react-intersection-observer"

const Card = styled(animated.div)`
  position: absolute;
  bottom: calc(50% - 21vw);
  right: calc(50% - 37.5vw);

  @media (orientation: portrait) {
    bottom: calc(50% - 56vw);
    right: calc(50% - 45vw);
  }
`

const HoverCard = ({ children, movedFromSide }) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const cardEl = useRef()
  const { ref, inView, entry } = useInView()

  const calc = (x, y) => {
    return [
      -parseInt(
        y -
          cardEl.current.getBoundingClientRect().top -
          cardEl.current.offsetHeight / 2
      ) / 20,
      parseInt(
        x -
          cardEl.current.getBoundingClientRect().left -
          cardEl.current.offsetWidth / 2
      ) / 30,
      1.1,
    ]
  }
  const trans = (x, y) => {
    return `perspective(2000px) rotateX(${x}deg) rotateY(${y}deg)`
  }

  const [springProps, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: {
      mass: 5,
      tension: 350,
      friction: 40,
    },
  }))

  const setFromEvent = ({ clientX: x, clientY: y }) => {
    set({ xys: calc(x, y) })
  }

  useEffect(() => {
    !isPortrait && inView && window.addEventListener("mousemove", setFromEvent)

    return () => {
      !isPortrait &&
        inView &&
        window.removeEventListener("mousemove", setFromEvent)
    }
  }, [isPortrait, inView])

  return (
    <Card
      ref={cardEl}
      style={{
        transform: springProps.xys.interpolate(trans),
      }}
    >
      <div ref={ref}>{children}</div>
    </Card>
  )
}

export default HoverCard
