import styled from "styled-components"

const Spacer = styled.div`
  height: ${({ spacerH }) => spacerH || '100vh'};
  height: ${({ spacerH }) => spacerH || 'calc(var(--vh, 1vh) * 100)'};
  width: 100%;
  
  @media (orientation: portrait) {
    height: ${({ spacerH }) => spacerH || '100vh'};
  }
`;

export default Spacer