import React, { Suspense, useRef, useEffect } from "react"
import { withPrefix } from "gatsby"
import * as THREE from "three"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { useMediaQuery } from "react-responsive"

import { useGLTF } from "@react-three/drei"
import { a } from "@react-spring/three"
import Scroll from "./top/Scroll"

function Welcome(props) {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const { nodes } = useGLTF(`${withPrefix("/")}w2dc-2.glb`)
  const ref = useRef()
  const [y] = Scroll(
    [0, typeof window !== "undefined" ? window.innerHeight : 1000],
    { domTarget: window },
    props.inView && !props.isShowingLoader,
  )

  useEffect(() => {
    props.setShowLoader(false)
  }, [])

  return (
    <a.mesh
      geometry={nodes.Welco.geometry}
      material={nodes.Welco.material}
      {...props}
      rotation-x={
        !isPortrait && y.to(y => (y / (isPortrait ? 4000 : 2000)) * Math.PI)
      }
      position-y={
        !isPortrait && y.to(y => (y * -1) / 55 + (isPortrait ? 0 : 23))
      }
      position-z={
        !isPortrait && y.to(y => (y / (isPortrait ? 3000 : 1000)) * 100)
      }
      ref={ref}
      scale={[80, 80, 80]}
    ></a.mesh>
  )
}

const Rig = ({ inView }) => {
  const { camera, mouse } = useThree()

  const vec = new THREE.Vector3()
  return useFrame(
    () =>
      inView &&
      camera.position.lerp(
        vec.set(mouse.x * 5, mouse.y * 3, camera.position.z),
        0.1
      )
  )
}

const TopCanvas = ({ inView, setShowLoader, isShowingLoader }) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  return (
    <React.Fragment>
      <Canvas
        dpr={[1, 2]}
        camera={{ position: [0, 0, isPortrait ? 120 : 60] }}
        gl={{ alpha: false }}
      >
        <color attach="background" args={["#f2f2f2"]} />
        <Suspense fallback={null}>
          <Welcome inView={inView} setShowLoader={setShowLoader} isShowingLoader={isShowingLoader} />
        </Suspense>
        <ambientLight color={"white"} intensity={1} />
        <Rig inView={inView} />
      </Canvas>
    </React.Fragment>
  )
}

export default TopCanvas
