import React from "react"
import styled, { keyframes } from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;

  height: 180px;
  position: relative;

  @media (orientation: portrait) {
    height: 50px;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  white-space: nowrap;
  transform: translateX(0);
`

const move = keyframes`
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(-200%, 0, 0);
  }
`

const Text = styled.div`
  display: flex;
  white-space: nowrap;
  width: auto;
  position: relative;
  animation: ${move} ${({ speed }) => ` ${speed}s linear infinite`};
  -webkit-backface-visibility: hidden;

  @media (orientation: portrait) {
    animation: ${move} ${({ speed }) => ` ${speed / 1.5}s linear infinite`};
  }
`

const Ticker = ({ children, speed }) => {
  return (
    <Wrapper>
      <Inner>
        <Text speed={speed && speed}>{children}</Text>
        <Text speed={speed && speed}>{children}</Text>
        <Text speed={speed && speed}>{children}</Text>
        <Text speed={speed && speed}>{children}</Text>
        <Text speed={speed && speed}>{children}</Text>
        <Text speed={speed && speed}>{children}</Text>
      </Inner>
    </Wrapper>
  )
}

export default Ticker
