import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
// import { useInView } from "react-intersection-observer"

import ImageMO11 from "../staticImages/ImageMO11"
import ImageMO12 from "../staticImages/ImageMO12"
import ImageMO13 from "../staticImages/ImageMO13"
import ImageMO14 from "../staticImages/ImageMO14"
import ImageMO15 from "../staticImages/ImageMO15"
import ImageMO16 from "../staticImages/ImageMO16"

import ImageMO21 from "../staticImages/ImageMO21"
import ImageMO22 from "../staticImages/ImageMO22"
import ImageMO23 from "../staticImages/ImageMO23"
import ImageMO24 from "../staticImages/ImageMO24"
import ImageMO25 from "../staticImages/ImageMO25"
import ImageMO26 from "../staticImages/ImageMO26"

import ImageMO31 from "../staticImages/ImageMO31"
import ImageMO32 from "../staticImages/ImageMO32"
import ImageMO33 from "../staticImages/ImageMO33"
import ImageMO34 from "../staticImages/ImageMO34"
import ImageMO35 from "../staticImages/ImageMO35"
import ImageMO36 from "../staticImages/ImageMO36"

import ImageMO41 from "../staticImages/ImageMO41"
import ImageMO42 from "../staticImages/ImageMO42"
import ImageMO43 from "../staticImages/ImageMO43"
import ImageMO44 from "../staticImages/ImageMO44"
import ImageMO45 from "../staticImages/ImageMO45"
import ImageMO46 from "../staticImages/ImageMO46"

import ImageMO51 from "../staticImages/ImageMO51"
import ImageMO52 from "../staticImages/ImageMO52"
import ImageMO53 from "../staticImages/ImageMO53"
import ImageMO54 from "../staticImages/ImageMO54"
import ImageMO55 from "../staticImages/ImageMO55"
import ImageMO56 from "../staticImages/ImageMO56"

import ImageMO61 from "../staticImages/ImageMO61"
import ImageMO62 from "../staticImages/ImageMO62"
import ImageMO63 from "../staticImages/ImageMO63"
import ImageMO64 from "../staticImages/ImageMO64"
import ImageMO65 from "../staticImages/ImageMO65"
import ImageMO66 from "../staticImages/ImageMO66"

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  display: block;

  @media (orientation: portrait) {
    margin-bottom: 10px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const Dot = styled.button`
  height: 54px;
  width: 54px;
  border-radius: 50%;
  padding: 20px;

  position: absolute;
  top: ${({ posY }) => posY};
  left: ${({ posX }) => posX};

  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: opacity 0.25s ease-in-out;

  span {
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 50%;
    display: block;
    pointer-events: none;

    @media (orientation: portrait) {
      display: none;
    }
  }

  @media (hover: hover) {
    transition: padding 0.2s ease-in-out;

    &:hover {
      padding: 18px;
    }
  }

  @media (orientation: portrait) {
    height: 32px;
    width: 32px;
    padding: 0px;
    background: transparent;
    border: 2px solid #fff;
  }
`

const Item = ({ data, setOpenProduct, showTags }) => {
  const wrapperEl = useRef(null)

  return (
    <Wrapper ref={wrapperEl}>
      <ImageWrapper>
        {
          {
            11: <ImageMO11 />,
            12: <ImageMO12 />,
            13: <ImageMO13 />,
            14: <ImageMO14 />,
            15: <ImageMO15 />,
            16: <ImageMO16 />,

            21: <ImageMO21 />,
            22: <ImageMO22 />,
            23: <ImageMO23 />,
            24: <ImageMO24 />,
            25: <ImageMO25 />,
            26: <ImageMO26 />,

            31: <ImageMO31 />,
            32: <ImageMO32 />,
            33: <ImageMO33 />,
            34: <ImageMO34 />,
            35: <ImageMO35 />,
            36: <ImageMO36 />,

            41: <ImageMO41 />,
            42: <ImageMO42 />,
            43: <ImageMO43 />,
            44: <ImageMO44 />,
            45: <ImageMO45 />,
            46: <ImageMO46 />,

            51: <ImageMO51 />,
            52: <ImageMO52 />,
            53: <ImageMO53 />,
            54: <ImageMO54 />,
            55: <ImageMO55 />,
            56: <ImageMO56 />,

            61: <ImageMO61 />,
            62: <ImageMO62 />,
            63: <ImageMO63 />,
            64: <ImageMO64 />,
            65: <ImageMO65 />,
            66: <ImageMO66 />,
          }[data.image_id]
        }

        {data.hotspots &&
          data.hotspots.map((item, i) => {
            return (
              <Dot
                type="button"
                show={showTags}
                key={item.hs_id}
                posX={item.posX}
                posY={item.posY}
                onClick={() => {
                  setOpenProduct(item.product_id)
                }}
              >
                <span></span>
              </Dot>
            )
          })}
      </ImageWrapper>
    </Wrapper>
  )
}

export default Item
