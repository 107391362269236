import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f2f2f2;
  z-index: 99999999999;

  transition: opacity 0.35s ease-in;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};

  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: center;
  justify-content: center;
`
const Logo = styled.div`
  width: 800px;
  height: 87.81px;

  max-width: 90vw;

  svg {
    width: 800px;
    height: auto;
    max-width: 90vw;
  }
`
const changeOpa = keyframes`
  0% {
    opacity: 1;
  }
  
  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
`

const Text = styled.h2`
  font-size: 16px;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);

  animation: ${changeOpa} 0.5s ease-in-out infinite;

  @media (orientation: portrait) {
    bottom: 160px;
  }
`

const Loader = ({ showLoader, wait, setWait }) => {
  const [hide, setHide] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setWait(false)
      if (typeof window !== "undefined") window.scrollTo(0, 0)
    }, 2000)
  }, [])

  useEffect(() => {
    if (!showLoader && !wait) {
      setTimeout(() => {
        setHide(true)
      }, 400)
    }
  }, [showLoader, wait])

  return (
    <Wrapper show={showLoader || wait} hide={hide}>
      <Logo>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 765.18512 84.22237"
          height="84.222374"
          width="765.18512"
          version="1.1"
          id="svg3336"
        >
          <defs id="defs3340">
            <clipPath id="clipPath3366" clipPathUnits="userSpaceOnUse">
              <path
                id="path3368"
                d="m 0,841.89 1190.551,0 0,-841.89 L 0,0 0,841.89 Z"
              />
            </clipPath>
          </defs>
          <g transform="matrix(1.25,0,0,-1.25,-361.50125,568.29225)" id="g3344">
            <g transform="translate(715.3633,387.2559)" id="g3346">
              <path
                id="path3348"
                d="m 0,0 -2.357,0 -33.1,66.487 14.568,0 19.92,-42.118 0.153,0 19.892,42.118 14.104,0 L 0,0 Z"
              />
            </g>
            <g transform="translate(417.6904,453.751)" id="g3350">
              <path
                id="path3352"
                d="m 0,0 0,-10.854 -32.023,0 0,-16.377 29.925,0 0,-10.457 -29.925,0 0,-16.656 34.948,0 0,-10.851 -47.623,0 0,65.195 L 0,0 Z"
              />
            </g>
            <g transform="translate(574.8379,453.751)" id="g3354">
              <path
                id="path3356"
                d="m 0,0 0,-10.854 -32.015,0 0,-16.847 29.917,0 0,-10.455 -29.917,0 0,-16.188 34.933,0 0,-10.851 -47.615,0 0,65.195 L 0,0 Z"
              />
            </g>
            <g transform="translate(815.2305,453.751)" id="g3358">
              <path
                id="path3360"
                d="m 0,0 0,-10.854 -32.016,0 0,-16.981 29.922,0 0,-10.456 -29.922,0 0,-16.053 34.944,0 0,-10.851 -47.625,0 0,65.195 L 0,0 Z"
              />
            </g>
            <g id="g3362">
              <g clipPath="url(#clipPath3366)" id="g3364">
                <g transform="translate(845.7031,388.5557)" id="g3370">
                  <path
                    id="path3372"
                    d="m 0,0 0,65.195 22.143,0 c 22.156,0 33.503,-13.497 33.503,-32.598 C 55.646,13.495 44.299,0 22.143,0 L 0,0 Z m 22.143,10.693 c 14.408,0 20.9,7.258 20.9,21.904 0,14.647 -6.492,21.942 -20.9,21.942 l -9.467,0 0,-43.846 9.467,0 z"
                  />
                </g>
                <g transform="translate(498.29,446.8018)" id="g3374">
                  <path
                    id="path3376"
                    d="m 0,0 -5.976,-8.314 c -5.309,3.47 -10.922,5.472 -17.791,5.472 -9.468,0 -12.282,-3.625 -12.282,-7.056 0,-11.655 37.971,-4.882 37.971,-27.933 0,-12.579 -10.87,-21.321 -26.232,-21.321 -10.66,0 -19.852,3.295 -27.33,11.7 l 6.827,7.916 c 6.281,-5.89 12.571,-8.871 20.701,-8.871 9.451,0 14.101,4.536 14.101,9.503 0,13.951 -38.95,4.543 -38.95,28.353 0,10.453 10.476,18.383 25.361,18.383 C -14.012,7.832 -5.172,4.456 0,0"
                  />
                </g>
                <g transform="translate(315.917,422.3877)" id="g3378">
                  <path
                    id="path3380"
                    d="m 0,0 -14.048,0 0,20.928 13.921,0 c 7.987,0 11.669,-3.907 11.669,-10.035 C 11.542,3.224 6.551,0 0,0 m 30.85,-33.605 -1.107,9.56 c -0.986,-0.207 -2.183,-0.31 -3.529,-0.31 -5.323,0 -7.991,1.628 -11.186,6.661 -1.185,1.868 -2.475,4.141 -3.577,5.924 -1.261,1.986 -2.083,2.853 -3.32,3.641 9.352,2.24 15.932,9.109 15.932,19.467 0,11.586 -8.304,20.017 -24.19,20.017 l -26.589,0 0,-65.187 12.668,0 0,23.928 5.697,0 c 3.228,0 4.693,-1.163 6.059,-3.266 1.311,-2.019 5.789,-9.432 5.789,-9.432 5.666,-8.964 11.947,-11.961 20.385,-11.961 3.038,0 5.562,0.508 6.968,0.958"
                  />
                </g>
                <g transform="translate(635.6406,422.3877)" id="g3382">
                  <path
                    id="path3384"
                    d="m 0,0 -14.047,0 0,20.928 13.922,0 c 7.986,0 11.666,-3.907 11.666,-10.035 C 11.541,3.224 6.551,0 0,0 m 30.85,-33.605 -1.108,9.56 c -0.986,-0.207 -2.181,-0.31 -3.529,-0.31 -5.324,0 -7.99,1.628 -11.186,6.661 -1.183,1.868 -2.476,4.141 -3.578,5.924 -1.26,1.986 -2.082,2.853 -3.318,3.641 9.351,2.24 15.932,9.109 15.932,19.467 0,11.586 -8.303,20.017 -24.188,20.017 l -26.59,0 0,-65.187 12.668,0 0,23.928 5.695,0 c 3.229,0 4.694,-1.163 6.059,-3.266 1.313,-2.019 5.789,-9.432 5.789,-9.432 5.666,-8.964 11.949,-11.961 20.385,-11.961 3.037,0 5.562,0.508 6.969,0.958"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Logo>

      <Text>
        <Trans>Loading experience</Trans>
      </Text>
    </Wrapper>
  )
}

export default Loader
