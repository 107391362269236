import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { StaticImage } from "gatsby-plugin-image"
import { withPrefix } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import { useMediaQuery } from "react-responsive"

import Heading from "./Heading"
import Footer from "./Footer"

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;

  @media (orientation: portrait) {
    display: block;
    height: auto;
    padding-bottom: 275px;
  }
`

const HeadingWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  @media (orientation: portrait) {
    position: relative;
    top: 0;
    padding: 40px 0 120px;
  }
`

const ImageWrapper = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity ${({ slow }) => (slow ? "0.45s" : "0.25s")} ease-in-out;

  position: absolute;
  bottom: 0;

  @media (orientation: portrait) {
    height: 150vw;
    object-fit: cover;
    bottom: 190px;

    div {
      height: 100%;
    }
  }

  &:after {
    @media (orientation: portrait) {
      content: "";
      display: ${({ abs }) => (abs ? "block" : "none")};
      position: absolute;
      height: 80px;
      left: 0px;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
  }
`

const Border = styled.div`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 32px;

  height: calc(100vh - 240px);
  height: calc((var(--vh, 1vh) * 100) - 240px);
  width: calc((100vh - 240px) / 2.16);
  width: calc(((var(--vh, 1vh) * 100) - 240px) / 2.16);

  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);

  @media (orientation: portrait) {
    top: auto;
    bottom: 0;
    width: calc(100vw - 145px);
    height: calc((100vw - 160px) * 2.16);
    position: relative;
  }
`

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: calc(((100vh - 240px) / 2.16) - 60px);
  width: calc(((var(--vh, 1vh) * 100) - 240px) / 2.16 - 60px);
  border-radius: 14px;
  position: absolute;
  bottom: 25px;
  left: 30px;
  background-color: #f2f2f2;
  font-size: 12px;
  color: #000;
  padding: 0 10px;
  text-align: center;

  @media (orientation: portrait) {
    font-size: 14px;
    width: calc((100vw - 145px) - 44px);
    left: 20px;
    bottom: 20px;
  }
`

const Qr = styled.div`
  position: absolute;
  bottom: 23px;

  right: -145px;

  img {
    height: 120px;
    width: 120px;
    transform: scale(${({ grow }) => (grow ? 1.1 : 1)});
    transition: transform 0.1s ease-in-out;
  }

  @media (orientation: portrait) {
    display: none;
  }
`

const Filter = () => {
  const { ref, inView } = useInView({
    threshold: 0.45,
  })
  const [showImage, setShowImage] = useState(false)
  const [showLightImage, setShowLightImage] = useState(false)
  const [grow, setGrow] = useState(false)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const timeout = useRef(null)

  const handleButtonClick = e => {
    if (!isPortrait) {
      e.preventDefault()

      clearTimeout(timeout.current)

      setGrow(true)

      timeout.current = setTimeout(() => {
        setGrow(false)
      }, 500)
    }
  }

  useEffect(() => {
    if (inView) {
      document.body.classList.add("dc-dark")
      setTimeout(() => {
        setShowImage(true)
      }, 100)
    } else {
      setShowImage(false)
      setTimeout(() => {
        document.body.classList.remove("dc-dark")
      }, 100)
    }
  }, [inView, setShowImage])

  return (
    <Wrapper ref={ref}>
      <HeadingWrapper>
        <Heading>
          <Trans>Enter the Dreamcore</Trans>
        </Heading>
      </HeadingWrapper>
      <ImageWrapper show={showImage}>
        <StaticImage src="../images/ar-dark.jpg" alt="AR preview 1" />
      </ImageWrapper>

      <ImageWrapper show={showLightImage} slow abs>
        <StaticImage src="../images/ar-light.jpg" alt="AR preview 2" />
      </ImageWrapper>

      <Border>
        <Button
          target="_blank"
          href={"https://www.instagram.com/ar/385397779774689/"}
          onClick={handleButtonClick}
        >
          <Trans>Try Meta Effect</Trans>
        </Button>

        <Qr grow={grow}>
          <img src={`${withPrefix("/")}qr.svg`} title="QR code" alt="QR code" />
        </Qr>
      </Border>
      <Footer setShowLightImage={setShowLightImage} />
    </Wrapper>
  )
}

export default Filter
