import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const Wrapper = styled.footer`
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;

  font-size: 12px;
  padding: 8px 22px;

  display: flex;
  justify-content: space-between;

  @media (orientation: portrait) {
    flex-direction: column;
    line-height: 20px;
    bottom: 15px;
  }
`

const Text = styled.div`
  display: flex;

  @media (orientation: portrait) {
    flex-direction: column;
    width: 100%;
    text-align: ${({ center }) => (center ? "center" : "left")};
    margin-bottom: ${({ ar }) => (ar ? "75px" : "0")};
  }
`

const ExternalLink = styled.a`
  margin-left: 14px;
  position: relative;

  @media (orientation: portrait) {
    text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
    margin-left: ${({ underline }) => (underline ? "7px" : "0")};
    margin-right: 7px;
    order: ${({ order }) => order};
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: #fff;

    @media (orientation: portrait) {
      display: none;
    }
  }
`

const Footer = ({ setShowLightImage }) => {
  const { t } = useTranslation()
  const { ref, inView, entry } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView) {
      setShowLightImage(true)
    } else {
      setShowLightImage(false)
    }
  }, [inView])

  return (
    <Wrapper ref={ref}>
      <Text ar center>
        <Text center>
          <Trans>Discover Meta AR Effect on</Trans>:
        </Text>
        <ExternalLink
          href={"https://www.instagram.com/ar/385397779774689/"}
          target={"_blank"}
          underline
        >
          Instagram
        </ExternalLink>
        <ExternalLink href={"https://www.facebook.com/fbcameraeffects/tryit/385397779774689/"} target={"_blank"} underline>
          Facebook Messenger
        </ExternalLink>
      </Text>

      <Text>
        <ExternalLink
          href={
            "https://www.reserved.com/special/store/?backurl=woman/inspired/welcome-2-dreamcore-collection"
          }
          target={"_blank"}
          order={4}
        >
          <Trans>Check whole collection</Trans>
        </ExternalLink>
        <ExternalLink
          href={"https://www.instagram.com/reserved/"}
          target={"_blank"}
          order={3}
        >
          <Trans>Reserved Instagram</Trans>
        </ExternalLink>
        <ExternalLink href={t("Cookies Link")} target={"_blank"} order={1}>
          <Trans>Cookies</Trans>
        </ExternalLink>
        <ExternalLink href={t("Privacy Link")} target={"_blank"} order={2}>
          <Trans>Privacy policy</Trans>
        </ExternalLink>
      </Text>
    </Wrapper>
  )
}

export default Footer
